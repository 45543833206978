import React from 'react';
import styled from 'styled-components';
import { FaEnvelope, FaFacebook, FaInstagram } from 'react-icons/fa';

const Footer = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <FooterText>© 2024 Eventara. All rights reserved.</FooterText>
        <SocialIcons>
          {/* <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
            <FaFacebook />
          </a> */}
          <a href="https://www.instagram.com/eventara.capture/" target="_blank" rel="noopener noreferrer">
            <FaInstagram />
          </a>
          <a href="mailto:info@eventara.com">
            <FaEnvelope />
          </a>
        </SocialIcons>
      </FooterContent>
    </FooterContainer>
  );
};

export default Footer;

const FooterContainer = styled.footer`
  width: 100%;
  padding: 20px 0;
  background-color: #a77258;
  color: white;
  text-align: center;
  margin-top: 50px;
  position: relative;
  bottom: 0;
`;

const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px 20px;
`;

const FooterText = styled.p`
  font-size: 0.9rem;
  margin: 0;
  color: #f1f1f1;
`;

const SocialIcons = styled.div`
  font-size: 1.5rem;

  a {
    margin: 0 10px;
    color: inherit;
    text-decoration: none;

    svg {
      cursor: pointer;
      transition: color 0.3s;

      &:hover {
        color: #fff0e1;
      }
    }
  }
`;
