import React, { useState, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import Countdown from 'react-countdown';
import { motion } from 'framer-motion';
import emailjs from '@emailjs/browser';
import Lottie from 'lottie-react';
import confetti from './rocket.json';
import a1 from './a1.json';
import a2 from './a2.json';
import launch from './launching.json';
import logo from './logo.png';
import Footer from './common/Footer';
// import ParticleBackground from './ParticleBackground'; // For interactive background

function App() {
  const [email, setEmail] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_szg944b', 'template_5kmwsxq', form.current, 'GdbslrmtVRR7N25kW')
      .then((result) => {
        setEmail('');
        setEmailSent(true);
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
  };

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <Lottie style={{ width: '300px' }} animationData={confetti} loop={false} />;
    } else {
      return (
        <CountdownWrapper>
          <CountdownItem>
            <Time>{days}</Time>
            <Label>Days</Label>
          </CountdownItem>
          <CountdownItem>
            <Time>{hours}</Time>
            <Label>Hours</Label>
          </CountdownItem>
          <CountdownItem>
            <Time>{minutes}</Time>
            <Label>Minutes</Label>
          </CountdownItem>
          <CountdownItem>
            <Time>{seconds}</Time>
            <Label>Seconds</Label>
          </CountdownItem>
        </CountdownWrapper>
      );
    }
  };

  return (
    <Container>
      {/* <ParticleBackground /> Animated background component */}
      <Header>
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
          <Logo src={logo} alt="Event Tech Logo" />
        </motion.div>

        <motion.div initial={{ y: -50, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 1 }}>
          <Title>Crafting Unforgettable Moments</Title>
          <SubTitle>AI-powered photo-sharing, interactive e-invites, personalized websites</SubTitle>
          <div style={{ display: 'flex', justifyContent: 'center', gap: '8px' }}>
            <Lottie style={{ width: '180px' }} animationData={a1} loop={true} />
            <Lottie style={{ width: '180px' }} animationData={a2} loop={true} />
          </div>
        </motion.div>
      </Header>

      <MainContent>
        <CountdownSection>
            
            <CountdownTitle>Launching soon in: <Lottie style={{ width: '100px' }} animationData={launch} loop={true} /></CountdownTitle>
          <motion.div
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.8, type: 'spring' }}
          >
            <Countdown date={new Date('2024-11-01T00:00:00')} renderer={renderer} />
          </motion.div>
        </CountdownSection>

        <NotifySection>
          <NotifyText>Get notified when we launch</NotifyText>
          {!emailSent ? (
            <EmailForm ref={form} onSubmit={sendEmail}>
              <EmailInput
                type="email"
                name="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <NotifyButton type="submit">Notify Me</NotifyButton>
            </EmailForm>
          ) : (
            <ThankYouMessage>Thank you! We will notify you when we launch.</ThankYouMessage>
          )}
        </NotifySection>
      </MainContent>
      <Footer />
    </Container>
  );
}

export default App;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  position: relative;
  background: beige;
  color: #2f2f2f;
  font-family: 'Poppins', sans-serif;
  text-align: center;
`;

const Header = styled.div`
  margin-top: 50px;
`;

const Logo = styled.img`
  width: 250px;
  margin-bottom: 20px;
`;

const Title = styled.h1`
  font-size: 3rem;
  color: #a77258;
  margin-bottom: 10px;
`;

const SubTitle = styled.h2`
  font-size: 1.5rem;
  color: #b99684;
  margin-bottom: 30px;
`;

const MainContent = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CountdownSection = styled.div`
  color: #5a3934;
`;

const CountdownTitle = styled.p`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
  display:flex;
  align-items:center;
`;

const CountdownWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
`;

const CountdownItem = styled.div`
  text-align: center;
  margin: 0 10px;
`;
const motionKeyframe = keyframes`
  0% { transform: scale(1); }
  100% { transform: scale(1.05); }
`;

const Time = styled.div`
  font-size: 2.5rem;
  font-weight: bold;
  color: #d87c6c;
  animation: ${motionKeyframe} 0.8s ease-in-out infinite alternate;
`;

const Label = styled.p`
  font-size: 1rem;
  color: #5a3934;
`;

const NotifySection = styled.div`
  margin-top: 30px;
`;

const NotifyText = styled.p`
  font-size: 1rem;
  margin-bottom: 10px;
  color: #5a3934;
`;

const EmailForm = styled.form`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const EmailInput = styled.input`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px 0 0 5px;
  width: 200px;
  outline: none;
`;

const NotifyButton = styled.button`
  padding: 10px 20px;
  background-color: #d87c6c;
  color: white;
  border: none;
  border-radius: 0 5px 5px 0;
  font-size: 1rem;
  cursor: pointer;

  &:hover {
    background-color: #bf6454;
  }
`;

const ThankYouMessage = styled.p`
  font-size: 1.2rem;
  color: #4caf50;
  margin-top: 10px;
  font-weight: bold;
`;


